export { default as browserClient } from './browser-client'
export type {
  Database,
  Enums,
  Tables,
  TablesInsert,
  TablesUpdate,
} from './database.types'
export * from './enums'
export { default as serverClient } from './server-client'
export { default as serverComponentClient } from './server-component-client'
