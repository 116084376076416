'use client'
import {
  createContext,
  type PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import OneSignal from 'react-onesignal'

export enum NotificationStatus {
  NoSupport = 'no-support',
  Enabled = 'enabled',
  Denied = 'denied',
  Disabled = 'disabled',
  Prompt = 'prompt',
}

const getNotificationStatus = (permission?: NotificationPermission) => {
  if (typeof window === 'object' && !('Notification' in window)) {
    return NotificationStatus.NoSupport
  }

  if (typeof Notification !== 'undefined') {
    const permission_ = permission || Notification.permission
    console.log('permission_', permission_)
    if (permission_ === 'granted') {
      return NotificationStatus.Enabled
    }
    if (permission_ === 'denied') {
      return NotificationStatus.Denied
    }
    if (permission_ === 'default') {
      return NotificationStatus.Prompt
    }
  }

  return NotificationStatus.Disabled
}

const askForNotificationPermission = async () => {
  try {
    await OneSignal.Notifications.requestPermission()
    return getNotificationStatus()
  } catch (e) {
    return NotificationStatus.Disabled
  }
}

export const NotificationContext = createContext({
  initialized: false,
  status: NotificationStatus.Prompt,
  enable: async () => NotificationStatus.Disabled,
  disable: () => {},
  logIn: async (_id: string, _email: string) => {},
  logOut: async () => {},
})

type NotificaitonProviderProps = PropsWithChildren<{ appId: string }>

export const NotificationProvider = ({
  appId,
  children,
}: NotificaitonProviderProps) => {
  const [isInitialized, setInitialized] = useState(false)
  const [status, setStatus] = useState<NotificationStatus>(
    getNotificationStatus(),
  )
  const isMountedRef = useRef(false)

  const initialize = useCallback(async () => {
    await OneSignal.init({
      appId,
      allowLocalhostAsSecureOrigin: true,
      promptOptions: {
        autoPrompt: false,
      },
      serviceWorkerParam: {
        scope: '/push/onesignal',
      },
    })
    setInitialized(true)
  }, [appId])

  useEffect(() => {
    if (isMountedRef.current) {
      return
    }

    isMountedRef.current = true
    initialize()
  }, [initialize, status])

  const enable = useCallback(async () => {
    if (!isInitialized) {
      return status
    }
    if (status === NotificationStatus.Enabled) {
      return status
    }

    const newStatus = await askForNotificationPermission()
    setStatus(newStatus)

    return newStatus
  }, [status, isInitialized])

  const disable = useCallback(() => {
    setStatus(NotificationStatus.Disabled)
    setInitialized(false)
  }, [])

  const logIn = useCallback(
    async (userId: string, email: string) => {
      if (isInitialized) {
        await OneSignal.login(userId)
        OneSignal.User.addEmail(email)
      }
    },
    [isInitialized],
  )

  const logOut = useCallback(async () => {
    if (isInitialized) {
      await OneSignal.logout()
    }
  }, [isInitialized])

  return (
    <NotificationContext.Provider
      value={{
        disable,
        enable,
        logIn,
        logOut,
        initialized: isInitialized,
        status,
      }}
    >
      {children}
    </NotificationContext.Provider>
  )
}

export const useNotification = () => {
  const context = useContext(NotificationContext)
  if (!context) {
    throw new Error(
      'useNotification must be used within a NotificationProvider',
    )
  }
  return context
}
