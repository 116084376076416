import { type CookieOptions,createServerClient } from '@supabase/ssr'
import { cookies } from 'next/headers'

import { type Database } from '../database.types'

const serverClient = (cookieStore: ReturnType<typeof cookies>) => {
  const supabase = createServerClient<Database>(
    process.env.NEXT_PUBLIC_SUPABASE_URL!,
    process.env.NEXT_PUBLIC_SUPABASE_ANON_KEY!,
    {
      cookies: {
        get(name: string) {
          return cookieStore.get(name)?.value
        },
        set(name: string, value: string, options: CookieOptions) {
          cookieStore.set({ name, value, ...options })
        },
        remove(name: string, options: CookieOptions) {
          cookieStore.set({ name, value: '', ...options })
        },
      },
    },
  )

  return supabase
}

export default serverClient
